/**
 * DO NOT CHANGE THIS GENERATED FILE MANUALLY
 */
const object___calendarPencil = ({
  id,
  svgAttrs = {},
  title,
  variant,
  size,
}) => {
  const idTitle = `${id}-title`;
  if (variant === 'outline' && size === 24)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path d="M2 15V4h2v2.1h2V4h5.05v2.1H13V4h2v6l1.95-3.9L17 2h-4V0h-1.95v2H6V0H4v2H0v15h11.5l1.02-2H2Z"/><path fill-rule="evenodd" d="M13.01 17.247 18.93 5.4 24 7.94l-5.918 11.848-1.056-.528-4.015-2.013Zm6.678-9.581-4.411 8.822 2.057 1.023 4.411-8.822-2.057-1.023ZM15.64 22.043 13 24.012v-4.224l3.014 1.495.374.188-.748.572Z" clip-rule="evenodd"/></svg>`;
  if (variant === 'solid' && size === 24)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path d="M2 15V6.95h14v3.55l2-4V2h-4.01L14 0h-1.94l-.01 2H6l.01-2H4.06l-.01 2H0v15h12.76l1-2H2ZM12.05 2h1.94v2h-1.94V2Zm-8 0H6v2H4.05V2Z"/><path fill-rule="evenodd" d="M14 17.77 19.38 7l4.61 2.31-5.38 10.77-.96-.48L14 17.77Zm2.33 3.99-2.4 1.79v-3.84l2.74 1.36.34.17-.68.52Z" clip-rule="evenodd"/></svg>`;
  if (variant === 'outline' && size === 48)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path fill-rule="evenodd" d="M4 8v26h21.5l-.885 1.79H2V6.08h9V2h2v4.08h13V2h2v4.08h9V11l-2 4V8h-7v3h-2V8H13v3h-2V8H4Zm3 9v-1.975h25v1.955l-.01.02H7Z" clip-rule="evenodd"/><path d="m38.412 12.5-1.41 2.825-1.79 3.585-8.38 16.8-.055.105-.135 1.685-.64 7.955a.846.846 0 0 0 .49.835.849.849 0 0 0 .955-.15l6.93-6.545 1.045-2.1 1.575-3.16 9-18.05-7.59-3.79.005.005Zm-10.74 31.27.17-2.055 1.32.66-1.49 1.395Zm2.69-2.525-2.39-1.195.2-2.55.095-1.19 2.38 1.19 2.405 1.2-2.69 2.545Zm4.85-6.835-.65 1.3-.805 1.6-3.2-1.6-1.615-.81 6.27-12.535 1.79-3.58 2.105-4.21 4.815 2.45-6.92 13.81-1.79 3.57v.005Z"/></svg>`;
  if (variant === 'solid' && size === 48)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path fill-rule="evenodd" d="M4 16.025V34h20.075l-.885 1.79H2V6h9v4h2V6h-1.995V2h2v4H26V2h2v4h8.5v4.545l-2.705 5.48H4ZM28 6h-2v4h2V6Z" clip-rule="evenodd"/><path d="m37.91 12.5-1.41 2.825-1.585 3.175-8.64 17.315-.775 9.64a.846.846 0 0 0 .49.835.849.849 0 0 0 .955-.15l6.93-6.545L45.5 16.285l-7.59-3.79v.005Zm-8.05 28.745-2.39-1.195.295-3.735 4.785 2.39-2.69 2.545v-.005Z"/></svg>`;
  if (variant === 'outline' && size === 96)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path d="M12.16 30.05V34h52.82l.02-.04v-3.91H12.16Z"/><path d="M8 69V16h13.92v5H26v-5h25v5h4.11v-5H69v14.5l4-8V12H55.11V4H51v8H26V4h-4.08v8H4v61h44l2-4H8ZM75.824 27.01l-2.82 5.65-3.58 7.17-16.76 33.6-.11.21-.27 3.37-1.28 15.91c-.05.71.33 1.37.98 1.67a1.698 1.698 0 0 0 1.91-.3l13.86-13.09 2.09-4.2 3.15-6.32 18-36.1L75.814 27l.01.01Zm-21.48 62.54.34-4.11 2.64 1.32-2.98 2.79Zm5.38-5.05-4.78-2.39.4-5.1.19-2.38 4.76 2.38 4.81 2.4-5.38 5.09Zm9.7-13.67-1.3 2.6-1.61 3.2-6.4-3.2-3.23-1.62 12.54-25.07 3.58-7.16 4.21-8.42 9.63 4.9-13.84 27.62-3.58 7.14v.01Z"/></svg>`;
  if (variant === 'solid' && size === 96)
    return `<svg ${Object.entries(svgAttrs)
      .map(([key, value]) => `${key}="${value}"`)
      .join(
        ' ',
      )} id="${id}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">${title ? `<title id="${idTitle}">${title}</title>` : ''}<path d="M8 69V32h60.2l4.8-9.68V12H55v8h-4v-8H26V4h-4.1v8h4.09v8h-4.1v-8H4v61h43.94l1.98-4H8Z"/><path d="M55 4h-4v8h4V4ZM75.82 27 73 32.65 69.83 39 52.55 73.63 51 92.91c-.05.71.33 1.37.98 1.67a1.698 1.698 0 0 0 1.91-.3l13.86-13.09L91 34.57l-15.18-7.58V27Zm-16.1 57.49-4.78-2.39.59-7.47 9.57 4.78-5.38 5.09v-.01Z"/></svg>`;
};
object___calendarPencil.iconName = 'object___calendarPencil';

export { object___calendarPencil };
