import { Box, SimpleGrid, VStack } from '@neui/layout';
import {
  Typography,
  Headline as NeuiHeadline,
  Text,
  sandTheme,
  interaction_arrows_arrowRight,
  Headline,
} from '@neui/styleguide-commerzbank';
import { useTracker } from '@utils/snowplowTracking';
import { useRuntimeSettings } from '@utils/config';
import { Section } from '@components/neui-components/atoms/Section';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import { useTranslation } from '@utils/i18n';
import { ChangeServicePortal } from '@components/ChangeServicePortal';
import { Image } from '@components/Image';
import { SelfServiceIconGroup } from '@components/SelfServiceIconGroup/SelfServiceIconGroup';
import { SandSection } from '@components/OBServices/OBServices';
import { fkIconGroupInfo } from '@static-content/iconGroupInfo';
import { styled } from '@neui/core';
import { IconLink, Wrapper } from '@components/neui-components/atoms/IconLink';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';
import { useMakeLink } from '@components/Link';

import { FkIndexPageProps } from '../pages/index';
import { InView } from 'react-intersection-observer';
import { handleInViewChange } from '@utils/helpers/misc';

export const FkIndexPage = ({ topCategories }: FkIndexPageProps) => {
  const {
    staticContent: { categories, mostReadArticles },
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();
  const { $t, language } = useTranslation();

  const { trackButtonClick } = useTracker(FkIndexPage.name);
  const makeLink = useMakeLink();

  return (
    <>
      <Section>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          <SelfServiceIconGroup
            actionIcons={fkIconGroupInfo(language)}
            sectionNumber={1}
            sectionTotal={4}
          />
        </VStack>
      </Section>
      <InView
        onChange={handleInViewChange(
          $t('CURRENTLY_POPULAR'),
          2,
          4,
          enableGA4Tracking,
        )}
        triggerOnce={true}
      >
        {({ ref }) => (
          <SandSection theme={'sand'} className={sandTheme} ref={ref}>
            <NeuiHeadline
              type="h3"
              renderAs="h2"
              textAlign={'left'}
              css={{ alignSelf: 'flex-start' }}
            >
              {$t('CURRENTLY_POPULAR')}
            </NeuiHeadline>
            <SimpleGrid
              spacing={{ base: '$space$component-8' }}
              columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
            >
              {mostReadArticles.map((article, index) => {
                const onClick = () => {
                  trackButtonClick?.(article.slug, 'top_article', []);
                  GA4TrackTeaserCtaClick(
                    'CTA Card',
                    article.title,
                    $t('READ_MORE'),
                    makeLink({
                      href: article.slug,
                      absoluteUrl: true,
                      alwaysPrependBasePath: true,
                    }),
                    $t('CURRENTLY_POPULAR'),
                    2,
                    4,
                  );
                };
                return (
                  <TeaserCard
                    key={index}
                    href={article.slug}
                    onClick={onClick}
                    hover
                    footer={
                      <StyledIconLink
                        iconPosition="right"
                        animationDirection="none"
                        renderAs={Typography}
                        icon={interaction_arrows_arrowRight}
                        data-cy={'top-article'}
                      >
                        {$t('READ_MORE')}
                      </StyledIconLink>
                    }
                  >
                    <VStack spacing={{ base: 12, md: 16 }}>
                      <Typography
                        size={4}
                        weight={'medium'}
                        role="heading"
                        aria-level={3}
                      >
                        {article.title}
                      </Typography>
                      <Typography textAlign={'left'}>
                        {article.excerpt}
                      </Typography>
                    </VStack>
                  </TeaserCard>
                );
              })}
            </SimpleGrid>
          </SandSection>
        )}
      </InView>
      <InView
        onChange={handleInViewChange(
          $t('CATEGORY_SECTION_HEADLINE'),
          3,
          4,
          enableGA4Tracking,
        )}
        triggerOnce={true}
      >
        {({ ref }) => (
          <Section ref={ref}>
            <VStack spacing={'$subsection'}>
              <NeuiHeadline
                type="h3"
                renderAs="h2"
                subline={$t('CATEGORY_SECTION_SUBLINE')}
              >
                {$t('CATEGORY_SECTION_HEADLINE')}
              </NeuiHeadline>
              <SimpleGrid
                spacing={{ base: '$space$component-8' }}
                columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
              >
                {topCategories.map((category, index) => {
                  const { slug, title } = category;
                  const categoryInfo = categories.find(
                    ([s]) => s === slug,
                  )?.[1];

                  if (categoryInfo === undefined) {
                    return null;
                  }

                  return (
                    <TeaserCard
                      key={index}
                      href={slug}
                      onClick={() => {
                        trackButtonClick?.(title, 'category', []);
                        GA4TrackTeaserCtaClick(
                          'CTA Picture',
                          title,
                          $t('READ_MORE'),
                          makeLink({
                            href: slug,
                            absoluteUrl: true,
                            alwaysPrependBasePath: true,
                          }),
                          $t('CATEGORY_SECTION_HEADLINE'),
                          3,
                          4,
                        );
                      }}
                      hover
                      bordered
                      picture={
                        <ImageWrapper>
                          <Image
                            src={categoryInfo.icon as string}
                            alt=""
                            loading="lazy"
                          />
                        </ImageWrapper>
                      }
                      footer={
                        <StyledIconLink
                          iconPosition="right"
                          animationDirection="none"
                          renderAs={Typography}
                          icon={interaction_arrows_arrowRight}
                          data-cy={'category'}
                        >
                          {$t('READ_MORE')}
                        </StyledIconLink>
                      }
                    >
                      <VStack spacing={{ base: 8, md: 16 }}>
                        <Typography
                          size={4}
                          weight={'medium'}
                          aria-level={3}
                          role="heading"
                        >
                          {title}
                        </Typography>
                        <Text textAlign={'left'} type="info">
                          {categoryInfo.description}
                        </Text>
                      </VStack>
                    </TeaserCard>
                  );
                })}
              </SimpleGrid>
            </VStack>
          </Section>
        )}
      </InView>
      <ChangeServicePortal />
    </>
  );
};

export const ImageWrapper = styled(Box, {
  position: 'relative',
  width: '100%',
  maxWidth: '375px',
  margin: '0 auto',
  height: 'auto',
  '& img, & picture, & video, & svg': {
    inset: 0,
    width: '100%',
    height: '100%',
  },
});

export const StyledIconLink = styled(IconLink, {
  '&:focus, &:focus-visible': {
    [`& ${Wrapper}`]: {
      outline: 'none',
    },
  },
});
