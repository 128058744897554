import { MakeStagedLinkFunc } from '@utils/helpers/linkHelpers';

export type OnlineBankingServicesProps = {
  title: string;
  slug: string;
  excerpt: string;
  categories: CategoryProps[];
};

export type CategoryProps = {
  categoryTitle: string;
  subcategories: SubcategoryProps;
};

export type SubcategoryProps = {
  subcategoryTitle: string;
  links: {
    linkTitle: string;
    href: string;
  }[];
}[];

export const getOnlineBankingServices = (
  link: MakeStagedLinkFunc,
): OnlineBankingServicesProps[] => {
  return [
    {
      title: 'Profil & Einstellungen',
      slug: '',
      excerpt:
        'Sie wollen persönliche Daten, Profileinstellungen oder Einstellungen im Online Banking ändern? Hier finden Sie alle Direktlinks',
      categories: [
        {
          categoryTitle: 'Digital Banking Profil',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Mein Profil - Personendaten',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SI93_Mein_Profil_Personendaten_Int',
                  ),
                },
                {
                  linkTitle: 'Mein Profil - Zugangswege',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SI94_Mein_Profil_Zugangswege_Int',
                  ),
                },
                {
                  linkTitle: 'Neue Teilnehmernummer anfordern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST01_TNR_anfordern',
                  ),
                },
                {
                  linkTitle: 'Benutzernamen ändern',
                  href: link(
                    'https://kunden.commerzbank.de/dailybanking/change-alias',
                  ),
                },
                {
                  linkTitle: 'Sicherheit im Digital Banking',
                  href: link(
                    'https://www.commerzbank.de/portal/de/privatkunden/hilfe-kontakt/services/sicherheit-onlinebanking/sicherheit-onlinebanking.html',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Kunden Profil',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Personendaten und Meldeadresse ändern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SP02_Personendaten_aendern_Int',
                  ),
                },
                {
                  linkTitle: 'Kontaktdaten ändern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SP23_Kontaktdaten',
                  ),
                },
                {
                  linkTitle: 'Einwilligung zur Verwendung Ihrer Daten',
                  href: link(
                    'https://kunden.commerzbank.de/digitalconsent/optincenter',
                  ),
                },
                {
                  linkTitle: 'Zustimmung zu Preis- u. Bedingungsänderungen',
                  href: link(
                    'https://kunden.commerzbank.de/digitalconsent/ztfe',
                  ),
                },
                {
                  linkTitle: 'Vollmachten anzeigen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Kunde/SP72_Vollmachten_anzeigen_Int',
                  ),
                },
                {
                  linkTitle:
                    'Vollmachten einrichten (natürliche Personen, selbstständige Privatpersonen, Freiberufler, Gewerbetreibende)',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Kunde/SP78_Vollmacht_anlegen_PUK_NAT_4AF_Int',
                  ),
                },
                {
                  linkTitle:
                    'Vollmachten einrichten (juristische Personen, BGB Gesellschaften, eingetragene Einzelkaufleute u.ä.)',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Kunde/SP79_Vollmacht_anlegen_PUK_JUR_4AF_Int',
                  ),
                },
                {
                  linkTitle: 'Vollmacht löschen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Kunde/SP80_Vollmachten_Loeschen_PUK_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Digital Banking Einstellungen',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'TAN-Einstellungen',
                  href: link(
                    'https://kunden.commerzbank.de/dailybanking/tan-management',
                  ),
                },
                {
                  linkTitle: 'Digital Banking PIN ändern',
                  href: link(
                    'https://kunden.commerzbank.de/dailybanking/change-pin',
                  ),
                },
                {
                  linkTitle: 'Digital Banking PIN anfordern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST07_TNV_PIN_anfordern',
                  ),
                },
                {
                  linkTitle: 'Digital Banking sperren',
                  href: link(
                    'https://kunden.commerzbank.de/dailybanking/lock-access',
                  ),
                },
                {
                  linkTitle: 'Überweisungslimit ändern',
                  href: link(
                    'https://kunden.commerzbank.de/dailybanking/change-transfer-limit',
                  ),
                },
                {
                  linkTitle: 'Fremdbankkonten-Verwaltung (Multibanking)',
                  href: link(
                    'https://kunden.commerzbank.de/banking/thirdPartyFIOverviewNewProcess?6',
                  ),
                },
                {
                  linkTitle: 'Zusatz-Dienste verwalten',
                  href: link(
                    'https://kunden.commerzbank.de/banking/online-access/additionalservices?pk',
                  ),
                },
                {
                  linkTitle: 'giropay',
                  href: link(
                    'https://kunden.commerzbank.de/banking/online-access/setup-paymentservice',
                  ),
                },
                {
                  linkTitle: 'Mobile Banking App Einstellungen löschen',
                  href: link(
                    'https://kunden.commerzbank.de/banking/online-access/additionalservices',
                  ),
                },
                {
                  linkTitle: 'HBCI aktivieren',
                  href: link(
                    'https://kunden.commerzbank.de/dailybanking/activate-hbci',
                  ),
                },
                {
                  linkTitle: 'HBCI einrichten',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST06_Anmeldung_HBCI',
                  ),
                },
                {
                  linkTitle: 'HBCI mit PIN/TAN aktivieren',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST12_Aktivierung_HBCI_PIN_TAN_Verfahren_Int',
                  ),
                },
              ],
            },
            {
              subcategoryTitle: 'Weitere Konten & Produkte freischalten',
              links: [
                {
                  linkTitle:
                    'Neue Kundennummer für das Digital Banking freischalten',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST04_TNV_Freischaltung_Kundennummer',
                  ),
                },
                {
                  linkTitle: 'Drittanbieter verwalten',
                  href: link(
                    'https://kunden.commerzbank.de/banking/psd2aisconsentmanagement',
                  ),
                },
                {
                  linkTitle: 'Alle Konten & Produkte freischalten',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST03_TNV_Freischaltung_Produkte',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Formulare und Aufträge',
      slug: '',
      excerpt: 'Alle Formulare auf einen Blick? Hier entlang.',
      categories: [
        {
          categoryTitle: 'Aval',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle:
                    'Auftrag zur Übernahme eines Avals - Unternehmerkunden',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC22_Avale_Anlage_UK_Int_4AF',
                  ),
                },
                {
                  linkTitle:
                    'Auftrag zur Änderung eines Avals - Unternehmerkunden',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC23_Aval_Aenderung_Int_4AF',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'EBICS / Service-Rechenzentrum',
          subcategories: [
            {
              subcategoryTitle: 'Service-Rechenzentrum (SRZ)',
              links: [
                {
                  linkTitle:
                    'Kontoinformationen über ein Service-Rechenzentrum einrichten',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI28_DFÜ_SRZ_CoInfo_Int_4AF',
                  ),
                },
                {
                  linkTitle: 'Service-Rechenzentrum Vereinbarung hochladen',
                  href: link('https://www.commerzbank.de/upload-SRZ'),
                },
                {
                  linkTitle:
                    'Prüfung zu elektronischen Umsatzlieferungen und Zahlungsaufträgen',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI36_DFÜ_SRZ_Prüfung_Int',
                  ),
                },
              ],
            },
            {
              subcategoryTitle: 'EBICS',
              links: [
                {
                  linkTitle: 'EBICS User anlegen',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI37_EBICS_User_anlegen_Int',
                  ),
                },
                {
                  linkTitle: 'EBICS User ändern',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI39_EBICS_User_ändern_Int',
                  ),
                },
                {
                  linkTitle: 'EBICS User löschen',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI38_EBICS_User_loeschen_Int_4AF',
                  ),
                },
                {
                  linkTitle: 'EBICS neues Konto einbinden',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI41_EBICS_Konto_einbinden_Int',
                  ),
                },
                {
                  linkTitle: 'EBICS Konto entfernen',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SI40_EBICS_Konto_entfernen_Int_4AF',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Baufinanzierung & Darlehen',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle:
                    'Auszahlungsauftrag (Kunden mit Online Banking-Zugang)',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC02_Darlehen_Auszahlung_mitOB',
                  ),
                },
                {
                  linkTitle:
                    'Auszahlungsauftrag (Kunden ohne Online Banking-Zugang)',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC01_Darlehen_Auszahlung_ohneOB',
                  ),
                },
                {
                  linkTitle:
                    'Auszahlungsauftrag Hochladen des unterschriebenen Auftrags (pdf)',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC03_Darlehen_Upload',
                  ),
                },
                {
                  linkTitle: 'Bestandsdarlehen: allgemeine Anfrage',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC04_Darlehen_Service&amp;x-kategorie=allgemeine%20anfrage',
                  ),
                },
                {
                  linkTitle: 'Bestandsdarlehen: Dokumenten-Upload',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC04_Darlehen_Service/&amp;x-kategorie=dokumentenupload',
                  ),
                },
                {
                  linkTitle: 'Bestandsdarlehen: Konditionsangebot anfordern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC04_Darlehen_Service&amp;x-kategorie=konditionsangebot',
                  ),
                },
                {
                  linkTitle: 'Bestandsdarlehen: Einzugskonto ändern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Darlehen/SC04_Darlehen_Service&amp;x-kategorie=einzugskonto%20aendern',
                  ),
                },
                {
                  linkTitle: 'Vertrauliche Selbstauskunft',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/efw-dokumente/Vertrauliche_Selbstauskunft.pdf',
                  ),
                },
                {
                  linkTitle:
                    'Vermögensaufstellung (Anlage zur vertraulichen Selbstauskunft)',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/produktinfo/vermoegensaufstellung.pdf',
                  ),
                },
                {
                  linkTitle: 'SEPA-Lastschriftmandat (Einzugsermächtigung)',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/efw-dokumente/sepa_lastschrift.pdf',
                  ),
                },
                {
                  linkTitle:
                    'Schufa-Klausel für grundpfandrechtlich gesicherte Darlehen',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/efw-dokumente/schufa_grund.pdf',
                  ),
                },
                {
                  linkTitle:
                    'Schufa-Klausel für nicht grundpfandrechtlich gesicherte Darlehen',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/efw-dokumente/schufa_nichtgrund.pdf',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Konto',
          subcategories: [
            {
              subcategoryTitle: 'Konto Produktaufträge',
              links: [
                {
                  linkTitle: 'Fremdwährungskonto eröffnen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB28_Konto_Fremdwaehrung_4AF_Int',
                  ),
                },
                {
                  linkTitle: 'Kontoproduktwechsel für Einzelkunden',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB54_Konto_Produktwechsel_Int',
                  ),
                },
                {
                  linkTitle:
                    'Kontoproduktwechsel für Minderjährige, Gemeinschaftskunden und Firmen (juristische Person)',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB61_Konto_Produktwechsel_Ausfuellhilfe_Int',
                  ),
                },
                {
                  linkTitle: 'Upload-Funktion für Produktanträge',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB15_Konto_AntragUpload_PUK_Int',
                  ),
                },
              ],
            },
            {
              subcategoryTitle: 'Serviceaufträge zu Konten',
              links: [
                {
                  linkTitle: 'Entgeltaufstellung gemäß Zahlungskontengesetz',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Weitere/SB26_ZKG_Kostenaufstellung',
                  ),
                },
                {
                  linkTitle: 'Fremdwährung (Bargeld) bestellen',
                  href: link(
                    'https://www.commerzbank.de/konten-zahlungsverkehr/service/reisezahlungsmittel',
                  ),
                },
                {
                  linkTitle: 'Kontoauszug nacherstellen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Weitere/SB07_Zweitschriften_Konto',
                  ),
                },
                {
                  linkTitle: 'Kontobezeichnung ändern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB22_Konto_Kontobezeichnung_Int',
                  ),
                },
                {
                  linkTitle: 'Kontobezeichnung ändern für ein Tagesgeldkonto',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB44_Tagesgeld_Kontobezeichnung_Int',
                  ),
                },
                {
                  linkTitle:
                    'Kontoinformationen über ein Service-Rechenzentrum',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?trackingID=LiveTest&path=/pk_sp/de/ZV/SI28_DFÜ_SRZ_CoInfo_INT_4AF',
                  ),
                },
                {
                  linkTitle: 'Scheckbestellung',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB11_Schecks_bestellen',
                  ),
                },
                {
                  linkTitle: 'SEPA-Lastschriftsperre einrichten / ändern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB20_SEPA_Lastschriftsperre',
                  ),
                },
              ],
            },
            {
              subcategoryTitle: 'Serviceaufträge im Kontext Pfändungen',
              links: [
                {
                  linkTitle: 'Zahlungsauftrag Kontopfändung',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Sonstiges/SS03_Pfaendung_FreigabeKunde',
                  ),
                },
                {
                  linkTitle:
                    'Wandlung eines Girokontos in ein Pfändungsschutzkonto',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Sonstiges/SS10_PfaendungSK_Wandlung_Int',
                  ),
                },
                {
                  linkTitle:
                    'Rückwandlung eines Pfändungsschutzkontos in ein Girokonto',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SS16_PfaendungSK_Rueckwandlung_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Karten',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Karte verwalten',
                  href: link('https://kunden.commerzbank.de/cards/carddetails'),
                },
                {
                  linkTitle: 'Karte für Internetnutzung sperren',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB05_Kreditkarten_OnlineLimit',
                  ),
                },
                {
                  linkTitle: 'Limit für Internet-Zahlungen festlegen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB05_Kreditkarten_OnlineLimit',
                  ),
                },
                {
                  linkTitle: 'Mastercard: Virtual Debit Card löschen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB63_Kreditkarte_Loeschung_virtDebitcard_Int/',
                  ),
                },
                {
                  linkTitle: 'Reklamation einer Buchung',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB66_Kreditkarte_Reklamation_Int',
                  ),
                },
                {
                  linkTitle: '3-Raten-Service aktivieren/deaktivieren',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB06_Kreditkarten_3Raten',
                  ),
                },
                {
                  linkTitle:
                    'Benachrichtigung EU-Preisverordnung Girocard / SparCard',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB03_ZVKarte_EU_Preisverordnung_Int',
                  ),
                },
                {
                  linkTitle:
                    'Benachrichtigung EU-Preisverordnung Mastercard / VISA',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SB01_MastercardVisa_EU_Preisverordnung_Int',
                  ),
                },
                {
                  linkTitle:
                    'Vormerkung für Großauszahlung an Commerzbank Geldautomaten',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Karten/SI08_ZVKarte_Auszahlungsvormerkung_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Ratenkredit',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Schufa-Hinweis / infoscore-Hinweis',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/produktinfo/Schufa_Infoscore.pdf',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Service im Zahlungsverkehr',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'POS-Bezahlterminal - Antragsformular einreichen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/ZV/SB86_ZV_POSGeraete_PaySquare_Int',
                  ),
                },
                {
                  linkTitle:
                    'Zahlungsverkehrsdateien/ Datenträger: Begleitzettel hochladen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB12_ZV_DTAUS_Begleitzettel_Int',
                  ),
                },
                {
                  linkTitle: 'Scheckbestellung',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB11_Schecks_bestellen',
                  ),
                },
                {
                  linkTitle: 'SEPA-Lastschriftsperre einrichten / ändern',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB20_SEPA_Lastschriftsperre',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Tagesgeld',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Kontobezeichnung ändern',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB44_Tagesgeld_Kontobezeichnung_Int',
                  ),
                },
                {
                  linkTitle: 'Referenzkonto ändern',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Konto/SB45_Tagesgeld_Referenzkonto_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Sparkonto',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Sparkonto Umbuchung',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Einlagen/SE35_Sparkonto_Umbuchung_Int',
                  ),
                },
                {
                  linkTitle: 'Referenzkonto ändern',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Einlagen/SE45_Sparkonto_Referenzkonto_Int',
                  ),
                },
                {
                  linkTitle: 'Betragskündigung erfassen',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Einlagen/SE12_Sparkonto_Betragskuendigung_Int',
                  ),
                },
                {
                  linkTitle: 'Betragskündigung stornieren',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Einlagen/SE14_Sparkonto_Betragskuendigung_Storno_Int',
                  ),
                },
                {
                  linkTitle: 'Kontobezeichnung ändern',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Einlagen/SE10_Sparkonto_Kontobezeichnung_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Wertpapiere',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle:
                    'Kundenkennung (Client Identifier / LEI) bearbeiten',
                  href: link(
                    'https://kunden.commerzbank.de/banking/clientidentifier',
                  ),
                },
                {
                  linkTitle:
                    'Übertrag von ausgewählten Wertpapieren (Teildepotübertrag) oder allen vorhandenen Wertpapieren (Gesamtdepotübertrag) aus einem Commerzbank-Depot',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/WP/SV03_WP_Depotuebertrag_Int',
                  ),
                },
                {
                  linkTitle:
                    'Depotwechselservice (Depoteinzug) von einem Fremdbankdepot zur Commerzbank',
                  href: link(
                    'https://www.commerzbank.de/investieren/produkte/depot/depotwechsel/',
                  ),
                },
                {
                  linkTitle:
                    'Anmeldung zum VV-Kundendialog (Telefonkonferenz für VV-Kunden)',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Kontakt/KN17_VV_Telefonkonferenz_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Sonstiges',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Bankauskunft (für Unternehmerkunden)',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Weitere/SA14_Bankauskunft_Int',
                  ),
                },
                {
                  linkTitle:
                    'Allgemeine Informationen zur Kontenwechselhilfe nach dem Zahlungskontengesetz',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/a-30-sonstige-medien/pdf/produktinfo/FirstSpirit_1483692340502Allgemeine_Informationen_zur_Kontowechselhilfe.pdf',
                  ),
                },
                {
                  linkTitle: 'Notfall-Card für den Urlaub',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/efw-dokumente/notfall_card.pdf',
                  ),
                },
                {
                  linkTitle: 'Edelmetalle bestellen',
                  href: link(
                    'https://www.commerzbank.de/sparen-anlegen/produkte/gold-kaufen/',
                  ),
                },
                {
                  linkTitle: 'Vermögensauskunft',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/WM/SW07_WM_Vermoegensauskunft_Int',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Ihre Meinung ist uns wichtig',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Ihre Zufriedenheit ist unser Ziel',
                  href: link(
                    'https://www.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Beschwerde',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Steuern',
      slug: '',
      excerpt:
        'Ihre Aufträge, die Finanzübersicht und alles rund Steuerinformationen finden Sie hier.',
      categories: [
        {
          categoryTitle: 'Auftrag / Übersicht',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Meine Steuerinformationen',
                  href: link(
                    'https://kunden.commerzbank.de/banking/taxinformation',
                  ),
                },
                {
                  linkTitle: 'Freistellungsauftrag erteilen',
                  href: link(
                    'https://kunden.commerzbank.de/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Steuern/SP57_Freistellungsauftrag_Int',
                  ),
                },
                {
                  linkTitle:
                    'Jahressteuerbescheinigung für Kapitalerträge im Betriebsvermögen',
                  href: link(
                    'https://kunden.commerzbank.de/portal/media/efw-dokumente/jahressteuerbescheinigung.pdf',
                  ),
                },
                {
                  linkTitle: 'Selbstauskunft zur steuerlichen Ansässigkeit',
                  href: link('https://kunden.commerzbank.de/clm/steuern/app'),
                },
                {
                  linkTitle: 'Common Reporting Standard',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/verwaltung/steuerinformationen/aktuelle-steuerthemen/crs/crs.html',
                  ),
                },
              ],
            },
          ],
        },
        {
          categoryTitle: 'Allgemeine Informationen',
          subcategories: [
            {
              subcategoryTitle: '',
              links: [
                {
                  linkTitle: 'Steuerinformationen (Änderungen ab 01.01.2021)',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/verwaltung/steuerinformationen/aktuelle-steuerthemen/aenderung-steuerliche-behandlung-verlustverrechnung.html',
                  ),
                },
                {
                  linkTitle: 'Freistellungsauftrag',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/serviceauftraege/freistellungsauftrag/freistellungsauftrag.html',
                  ),
                },
                {
                  linkTitle:
                    'Hinweise zur Jahressteuerbescheinigung / Erträgnisaufstellung',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/verwaltung/steuerinformationen/aktuelle-steuerthemen/jahresendreporting/jahresendreporting.html',
                  ),
                },
                {
                  linkTitle: 'Steuerumgehungsbekämpfungsgesetz',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/verwaltung/steuerinformationen/aktuelle-steuerthemen/steuerumgehungsbekaempfungsgesetz/steuerumgehung.html',
                  ),
                },
                {
                  linkTitle: 'Änderung bei der Kirchensteuer ab 01.01.2015',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/verwaltung/steuerinformationen/aktuelle-steuerthemen/kirchensteuer/kirchensteuer.html',
                  ),
                },
                {
                  linkTitle: 'FATCA',
                  href: link(
                    'https://kunden.commerzbank.de/portal/de/privatkunden/persoenlicher-bereich/verwaltung/steuerinformationen/aktuelle-steuerthemen/fatca/fatca.html',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
