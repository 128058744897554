import Head from 'next/head';
import { useRouter } from 'next/router';
import { VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Section } from '@components/neui-components/atoms/Section';
import { useTranslation } from '@utils/i18n';
import { createPortalEntity } from '@utils/snowplowTracking';
import { ChatEntryPoint } from '@components/Chat/ChatEntryPoint';
import { selectChatProps, useChatStore } from '@components/Chat/store/store';
import { GA4SearchType } from '@utils/tracking';
import { getSearchParameters } from '@components/Search/helpers';
import { CdsSearch } from '@components/Search/CdsSearch';
import { SuggestionResultType } from '@components/Search';
import { Categories } from '@components/Categories/Categories';
import { SelfServiceIconGroup } from '@components/SelfServiceIconGroup/SelfServiceIconGroup';
import { OBServices } from '@components/OBServices/OBServices';
import { useRuntimeSettings } from '@utils/config';
import { iconGroupInfo } from '@static-content/OB-iconGroupInfo';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';

import { CdsIndexPageProps } from '../pages/index';
import { XColumnsGridItem } from './XColumnsGridItem';

export const OBIndexPage = ({ topCategories }: CdsIndexPageProps) => {
  const {
    build: { stage },
    search: { apiUrl: searchApiUrl, topSearchTerms },
  } = useRuntimeSettings();
  const { $t } = useTranslation();
  const portalContext = createPortalEntity('lp', OBIndexPage.name);
  const makeStagedLink = makeStagedLinkFunction(stage);

  const router = useRouter();
  const [, setSuggestions] = useState<SuggestionResultType[]>([]);
  const { chatState } = useChatStore(selectChatProps);
  const resetQuery = useRef<(query: string) => void>();
  const [, setIsSearchFocused] = useState(false);
  const searchParameters = getSearchParameters(router);

  useEffect(() => {
    document.body.classList.add('lp');
  }, []);

  const isLeavingPage = useRef(false);

  useEffect(() => {
    const handlePageLeave = (target: string) => {
      if (!target.startsWith('/lp')) {
        isLeavingPage.current = true;
      }
    };

    router.events.on('routeChangeStart', handlePageLeave);

    return () => {
      router.events.off('routeChangeStart', handlePageLeave);

      if (isLeavingPage.current) {
        document.body.classList.remove('lp');
      }
    };
  }, [router]);

  const executeSearch = async (query: string, searchType: GA4SearchType) => {
    window.location.href = `${router.basePath}/?q=${query}&searchType=${searchType}`;
  };

  return (
    <>
      <Head>
        <meta name="de-comdirect-provider-template" content="layout_5_0" />
      </Head>
      <Section css={{ '& > div': { gap: '$layout-2' } }}>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          <SearchContainer>
            <CdsSearch
              searchUrl={searchApiUrl}
              initialQuery={searchParameters.q}
              executeSearch={executeSearch}
              onFocusChange={setIsSearchFocused}
              onSuggestionsChange={setSuggestions}
              setResetQuery={(f) => (resetQuery.current = f)}
              variant="base"
              compactLayout={true}
              mostSearchedTerms={topSearchTerms}
              pageType={'Landing Page'}
            />
          </SearchContainer>
          <SelfServiceIconGroup
            actionIcons={iconGroupInfo(makeStagedLink)}
            isOB={true}
            sectionNumber={1}
            sectionTotal={3}
          />
        </VStack>
      </Section>
      {chatState.workgroupName && (
        <StyledSection>
          <XColumnsGridItem columns={{ base: 12, lg: 8 }}>
            <ChatEntryPoint
              headline={'Wir sind für Sie da'}
              text={
                'Persönlich und rund um die Uhr. Im authentifizierten Kundencenter können wir nicht nur Ihre Fragen beantworten, sondern auch viele weitere Leistungen anbieten. Vom Girokonto bis zur neuen Kreditkarte.'
              }
              portalContext={portalContext}
            />
          </XColumnsGridItem>
        </StyledSection>
      )}
      <OBServices />
      <Categories
        topCategories={topCategories}
        sectionNumber={3}
        sectionTotal={3}
      />
    </>
  );
};

export const StyledSection = styled(Section, {
  paddingTop: '0 !important',
});

const SearchContainer = styled(VStack, {
  position: 'relative',
  width: '100%',
  maxWidth: '792px',
  height: '66px',
  marginBottom: '$layout-2',
});
